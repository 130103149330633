import http from '@/utils/http'

// 获取详情
export function getRoomList(data) {
  return http.get({
    url: '/api/room/list',
    data: data
  })
}
export function getRoomDetail(data) {
  return http.get({
    url: '/api/room/detail',
    data: data
  })
}
export function getRoomHotList(data) {
  return http.get({
    url: '/api/room/hotlist',
    data: data
  })
}
// 获取详情
export function getCateList(data) {
  return http.get({
    url: '/api/tags/list',
    data: data
  })
}
// 添加管理员
export function getSceneList(data) {
  return http.get({
    url: '/api/scene/list',
    data: data
  })
}
export function getSceneDetail(data) {
  return http.get({
    url: '/api/scene/detail',
    data: data
  })
}
// 管理员列表
export function createScene(data) {
  return http.post({
    url: '/api/room/create',
    data: data
  })
}
// 设置管理员状态
export function getUserDetail(data) {
  return http.get({
    url: '/api/user/detail',
    data: data
  })
}

export function updateUser(data) {
  return http.post({
    url: '/api/user/update',
    data: data
  })
}
export function getAvatasList(data) {
  return http.get({
    url: '/api/avatars/avatarlist',
    data: data
  })
}
// 获取详情
export function login(data) {
  return http.post({
    url: '/api/auth/mobile/login',
    data: data
  })
}
export function wxCallback(data) {
  return http.get({
    url: '/api/auth/wechat/callback',
    data: data
  })
}
// 获取详情
export function getCode(data) {
  return http.get({
    url: '/api/auth/mobile/getcode',
    data: data
  })
}
