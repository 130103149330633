<template>
  <div class="wx-login">
  </div>
</template>
<script>
import Cookies from 'js-cookie'
import { wxCallback } from '@/api/api'
export default {
  name: "wxLogin",
  methods: {},
  data() {
    return {
      code: '',
      state: '',
    };
  },
  created() {},
  methods: {
    wxCallback() {
      if (!this.code) {
        // top.location.replace(`https://app.metaio.cc/`);
      } else {
        this.$store.dispatch('user/bind', { code: this.code, state: this.state }).then(res => {
          this.loading = false
          if (res.code == 200) {
             this.$router.push({ path: '/square' })
           }else if (res.code == 201) {
            Cookies.set('unionid', res.data.unionid)
            Cookies.set('name', res.data.name)
            Cookies.set('gender', res.data.gender)
            this.$router.push({ path: "/bind" });
          } else if (res.code == 202) {
            this.$router.push({ path: "/login" });
          }
        }).catch(_ => {
          this.$router.push({ path: "/login" });
        })
      }
    },

  },
  mounted() {
    this.$nextTick().then(res => {
      const query = this.$route.query
      this.code = query.code
      this.state = query.state
      this.wxCallback()
    })
  }
};

</script>
<style scoped lang="scss">
.wx-login {
  position: relative;
  background-color: #000;
  width: 100%;
  height: 100%;

  .back-direction {
    position: absolute;
    top: 66px;
    left: 75px;
    cursor: pointer;

    img {
      width: 73px;
      height: 66px;
    }
  }

  .wx-login-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      font-weight: 700;
      font-size: 40px;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    img {
      margin: 60px 0;
    }

    .wx-login-tips {
      width: 631px;
      height: 109px;
      font-size: 32px;
      text-align: center;
      line-height: 109px;
      background: #222222;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 93.5px;
    }
  }
}

</style>
